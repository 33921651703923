<template>
  <div class="container">
    <PublicHeader :totalName="$t('public').xq" />
    <div class="title">{{ title }}</div>
    <div class="content" v-html="info"></div>
  </div>
</template>

<script>
import PublicHeader from './components/publicHeaderNew.vue'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      info: '',
      title: ''
    }
  },

  async mounted() {
    if (this.$route.query && this.$route.query.info) {
      this.info = this.$route.query.info
      this.title = this.$route.query.title
    } else {
      const type = this.$route.query.type
      this.title =
        type === 'AboutUs' ? this.$t('home').gywm : this.$t('home').bps
      this.info = await userApi.textInfo({
        isWhite: type !== 'AboutUs'
      })
    }
  }
}
</script>

<style scoped>
.container {
  padding-top: 44px;
  width: 80%;
  margin: 0 auto;
}
.title {
  font-size: 16px;
  margin: 20px 0;
  font-weight: bold;
  text-align: center;
}
.content {
  margin: 10px;
  /* background: #17181e; */
  overflow: hidden;
  padding: 10px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 30px;
  font-weight: normal;
}
</style>
